import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../index.css";
import "../../App.css";
import "./Wartung.css";

function Wartung() {
  return (
    <div className="wartung">
      <div className="textwrapper">
        <h2 className="headline">WARTUNG</h2>
        <p className="text">Wir arbeiten gerade an einer neuen Webseite.</p>
      </div>
    </div>
  );
}

export default Wartung;

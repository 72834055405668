import "./App.css";
import React from "react";
import { client } from "./contentful";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Damen1 from "./components/TeamsPage/Damen1";
import News from "./components/News/News";
import NewsPage from "./components/News/NewsPage";
import Vorstand from "./components/Vorstand/Vorstand";
import Datenschutz from "./components/FooterLinks/Datenschutz";
import Impressum from "./components/FooterLinks/Impressum";
import CoronaSchutz from "./components/FooterLinks/CoronaSchutz";
import Herren1 from "./components/TeamsPage/Herren1";
import Damen2 from "./components/TeamsPage/Damen2";
import BeachVolleyball from "./components/TeamsPage/Beach";
import Cookies from "./components/FooterLinks/Cookies";
import Gymnastik1 from "./components/TeamsPage/Gymnastik1";
import Gymnastik2 from "./components/TeamsPage/Gymnastik2";
import Senioren from "./components/TeamsPage/Senioren";
import Kursangebot from "./components/TeamsPage/Kursangebot";
import Downloads from "./components/Downloads/Downloads";
import Satzung from "./components/FooterLinks/Satzung";
import Kids1 from "./components/TeamsPage/Kids1";
import Kids2 from "./components/TeamsPage/Kids2";
import Kids3 from "./components/TeamsPage/Kids3";
import Tanzen1 from "./components/TeamsPage/Tanzen1";
import Tanzen2 from "./components/TeamsPage/Tanzen2";
import Tanzen3 from "./components/TeamsPage/Ballschule";
import KidsAnfaenger from "./components/TeamsPage/KidsAnfaenger";
import Termine from "./components/Termine/Termine";
import Hobby from "./components/TeamsPage/Hobby";
import Wartung from "./components/Wartung/Wartung";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client
      .getEntries({ content_type: "home", order: "sys.createdAt" })
      .then((res) => {
        dispatch({ type: "setHomeNews", payload: res.items });
      });
  });

  useEffect(() => {
    client
      .getEntries({ content_type: "vorstand", order: "fields.positionierung" })
      .then((res) => {
        dispatch({ type: "setVorstand", payload: res.items });
      });
  });

  useEffect(() => {
    client
      .getEntries({ content_type: "homeSpiele", order: "fields.fullDate" })
      .then((res) => {
        dispatch({ type: "setStartGames", payload: res.items });
      });
  });

  useEffect(() => {
    client
      .getEntries({ content_type: "news", order: "-sys.createdAt" })
      .then((res) => {
        dispatch({ type: "setNews", payload: res.items });
      });
  });

  useEffect(() => {
    client
      .getEntries({
        content_type: "homeSlideshow",
        order: "fields.positionierung",
      })
      .then((res) => {
        dispatch({ type: "setHomeSlideshows", payload: res.items });
      });
  });

  useEffect(() => {
    client
      .getEntries({ content_type: "mannschaften", order: "sys.createdAt" })
      .then((res) => {
        dispatch({ type: "setMannschaften", payload: res.items });
      });
  });

  return (
    <Router hashType="hashbang">
      <Routes>
        {/* <Route exact path="/" element={<Home />}></Route>
        <Route path="/news" element={<News />}></Route>
        <Route path="/news/:id" element={<NewsPage />}></Route>
        <Route path="/teams/damen-1" element={<Damen1 />}></Route>
        <Route path="/teams/damen" element={<Damen1 />}></Route>
        <Route path="/teams/damen-2" element={<Damen2 />}></Route>
        <Route path="/teams/herren-1" element={<Herren1 />}></Route>
        <Route path="/teams/herren" element={<Herren1 />}></Route>
        <Route path="/teams/beach" element={<BeachVolleyball />}></Route>
        <Route path="/teams/hobby" element={<Hobby />}></Route>
        <Route path="/gymnastik/gruppe-1" element={<Gymnastik1 />}></Route>
        <Route path="/gymnastik/gruppe-2" element={<Gymnastik2 />}></Route>
        <Route path="/gymnastik/senioren" element={<Senioren />}></Route>
        <Route path="/gymnastik/kursangebot" element={<Kursangebot />}></Route>
        <Route path="/kids/kids-1" element={<Kids1 />}></Route>
        <Route path="/kids/kids-2" element={<Kids2 />}></Route>
        <Route path="/kids/kids-3" element={<Kids3 />}></Route>
        <Route path="/kids/tanzen-1" element={<Tanzen1 />}></Route>
        <Route path="/kids/tanzen-2" element={<Tanzen2 />}></Route>
        <Route path="/kids/ballschule" element={<Tanzen3 />}></Route>
        <Route path="/kids/anfaenger" element={<KidsAnfaenger />}></Route>
        <Route path="/vorstand" element={<Vorstand />}></Route>
        <Route path="/datenschutz" element={<Datenschutz />}></Route>
        <Route path="/impressum" element={<Impressum />}></Route>
        <Route path="/corona" element={<CoronaSchutz />}></Route>
        <Route path="/cookies" element={<Cookies />}></Route>
        <Route path="/downloads" element={<Downloads />}></Route>
        <Route path="/satzung" element={<Satzung />}></Route>
        <Route path="/termine" element={<Termine />}></Route> */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Wartung />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
